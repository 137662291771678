.reactprojects{
    padding-top: 12rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reactprojects__h1{
    padding-left: 4rem;
    font-size: 800%;
}

.reactProjectCardWrapper{
    width: 100%;
}