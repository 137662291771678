.card{
    width: 100rem;
    height: 40rem;
    display: flex;
    color: #FFECEF;
    margin: 5rem 0;
    z-index: 700;
    gap: 2rem;
}

.card__img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    position:relative;
    z-index:-1;
    display:block;
    width: 100%;
    height: 100%;
}

.card__img--wrapper{
    display: inline-block;
    height: 100%;
    width: 40%;
}

.card__infoWrapper{
    width: 60%;
    display: flex;
    padding: 1rem 2rem;
    flex-direction: column;
    justify-content: space-between;
    font-size: 120%;
}

.card__info{
    height: 70%;
    width: 100%;
}
.card__h1{
    font-size: 400%;
    color: #eee;
}
.card__description{
    font-size: 150%;
}
.card__button{
    font-size: 150%;
    background: none;
    color: white;
    border: solid 2px white;
    padding: 0.3rem;
    border-radius: 0.5rem;
    cursor: pointer;
    text-decoration: none;
    margin: 1rem;
}

.card__methods{
    width: 100%;
    font-size: 150%;
}
.card__methodlist{
    list-style: none;
}
.card__githubLink{
    color: white;
    font-size: 200%;
    i{
        cursor: pointer;
    }
}

@media screen and (max-width: 1050px) {
    .card{
        flex-direction: column;
        width: 70rem;
        min-height: 60rem;
        height: fit-content;
    }
    .card__img--gradient{
        width: 100%;
        height: 60%;
    }
    .card__img{
        width: 100%;
        height: 100%;
    }
    .card__img--wrapper{
        width: 100%;
    }
    .card__infoWrapper{
        width: 100%;
        height: fit-content;
    }
}

@media screen and (max-width: 750px) {
    .card{
        width: 40rem;
        min-height: 50rem;
        height: fit-content;
    }
}