.about{
    padding: 12rem;
    color: #8A8ABD;
    @media screen and (max-width:800px){
        padding: 5rem;
    }
        
}

.about__titleWrapper{
    display: flex;
    justify-content: center;
}

.aboutWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 5rem;
}

.aboutTextWrapper{
    display:  flex;
    flex-direction: column;
    gap: 5rem;
    width: 55%;
    height: 100%;
    min-width: 50rem;
    @media screen and (max-width:800px){
        min-width: auto;
        width: 100%;
    }
}

.aboutSection{
    font-size: 150%;
}

.aboutSection__p{
    color: #eee;
}

.about__h1{
    color: #eee;
    font-size: 800%;
    padding: 3rem;
}

.about__h2{
    font-size: 300%;
    color: #8A8ABD;
}

.goalsSection{
}

.goals__h2{
    font-size: 350%;
}

.goalsListWrapper{
    font-size: 200%;
    color: #eee;
}

.goalsList{
    list-style: circle;
}

.goalsListItem{

}

.qualitiesSection{
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5rem;
}

.qualities{
    width: 30rem;
    height: fit-content;
    padding: 2rem;
    display:  flex;
    flex-direction: column;
    gap: 2rem;
}
.qualities__h2{
    font-size: 300%;
    color: #eee;
}

.quality{

}
.quality__h2{
 color: #eee;
}

.languages{
    color: #eee;
    width: 30rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
}
.languages__title{
    font-size: 300%;
    width: 100%;
}
.languages__h2{

}
.languages__h2--spanish{
    color: rgba(237, 237, 237, 0.6);
}