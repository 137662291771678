.projectsSection{
    padding-top: 12rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.projects__h1{
    padding: 0 4rem 2rem;
    color: #fff;
    font-size: 800%;
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid hsl(350, 79%, 54%);
    @media screen and (max-width: 500px) {
        font-size: 500%;
        
    }
}

.projects{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
}

.projects__filterwrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 8rem;
    @media screen and (max-width: 500px) {
        flex-direction: column;
        align-items: center;
        gap: 0;
    }
}
.projects__filterwrapper__filter{
    color: hsl(350, 79%, 54%);
    font-family: inherit;
    font-size: 1.8rem;
    padding: 0.3rem;
    background: none;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid hsl(350, 79%, 54%);
    position: relative;
    cursor: pointer;
    

    &::before{
        content: "";
        position: absolute;
        display: block;
        height: 4rem;
        border-left: 1px solid hsl(350, 79%, 54%);
        left: -14px;
        transform: rotate(-45deg);
        bottom: -6px;
    }
    &::after{
        content: "";
        position: absolute;
        display: block;
        height: 4rem;
        border-right: 1px solid hsl(350, 79%, 54%);
        right: -14px;
        transform: rotate(45deg);
        bottom: -6px;
    }
    
    &--selected{
        background: hsl(350, 79%, 54%);
        color: #1a1a2e;
        &::before{
            height: 100%;
            aspect-ratio: 1/1;
            background: linear-gradient(45deg, transparent 50%, hsl(350, 79%, 54%) 50%);
            top: 0;
            left: 0;
            bottom: auto;
            border-left: none;
            transform: translateX(-100%);
        }
        &::after{
            height: 100%;
            aspect-ratio: 1/1;
            background: linear-gradient(-45deg, transparent 50%, hsl(350, 79%, 54%) 50%);
            top: 0;
            right: 0;
            bottom: auto;
            border-right: none;
            transform: translateX(100%);
        }
    }
    @media screen and (max-width: 500px) {
        &::before, &::after{
            content: none;
        }
        width: 100%;
        padding: 1.4rem;
        font-size: 2rem;
    }
}