.footer{
    height: 15vh;
    border-top: solid 1px rgb(83, 82, 82);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 200%;
    flex-wrap: wrap;
}

.footer__ul{
    list-style: none;
    display: flex;
    gap: 5rem;
    
    @media screen and (max-width: 50rem) {
        flex-direction: column;
        padding: 5rem 0;
    }
}

.footer__link{
    color: #eee;
    text-decoration: none;
}