.GlueRoutesystem__1{
    padding: 14rem 10rem;
    height: 100vh;
    width: 100%;
    position: relative;
}

.GlueRoutesystem__img{
    position: absolute;
    top: 14rem;
    left: 10rem;
    width: 60%;
    height: 60rem;
    object-fit: cover;
}

.GlueRoutesystem__info{
    position: absolute;
    bottom: 10rem;
    right: 10rem;
    height: 60rem;
    width: 60%;
    background: #372948;
    box-shadow: 0 0 2rem black;
    padding: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow:auto;
}

.GlueRoutesystem__h1{
    color: #78938A;
    font-size: 600%;
}

.GlueRoutesystem h2{
    color: #78938A;
    font-size: 160%;
}

.GlueRoutesystem__p{
    font-size: 130%;
}

.GlueRoutesystem__linknaarrobin{
    color: #78938A;
}

.GlueRoutesystem__links{
    
}

.GlueRoutesystem__2{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    
    flex-wrap: wrap;
}

.GlueRoutesystem__extraInfo{
    height: 60rem;
    width: 60%;
    background: #372948;
    box-shadow: 0 0 2rem black;
    padding: 5rem;
    margin: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.GlueRoutesystem__infobubble{
    position: absolute;
    bottom: 5rem;
    right: 5rem;
}

.GlueRoutesystem__documentationLinks{
    display:  flex;
    flex-direction: column;
    gap: 1rem;
}

.GlueRoutesystem__documentationLinks > a{
        
}