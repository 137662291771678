

.mainInHomePage {
  height: 100vh;
}

.sectionInHomePage {
  height: 100vh;
  position: relative;
}

.frontSection{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.article{
    height: 60rem;
    width: 80rem;
    border-radius: 2rem;
    padding:  10rem 4rem;
}
.article__h2{
    font-size: 250%;
}
.article__h1{
    font-size: 1000%;
}
.article__p{
font-size: 200%;
}
.article__more{
    border: none;
    padding: 2rem 3rem;
    font-size: 200%;
    background: hsl(350, 79%, 54%);
    color: #000;
    cursor: pointer;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.4);
    margin: 2rem;
}

.projectSection{
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
}

.projectsTitle{
    font-size: 300%;
    text-shadow: 0 0 2rem rgba(0, 0, 0, 0.4);
}

.projectsButton{
    border: none;
    padding: 2rem 3rem;
    font-size: 200%;
    background: #e62d4c;
    color: black;
    cursor: pointer;
}

.projectsButton:hover{
    background: hsl(350, 79%, 50%);
}