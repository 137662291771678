.temporaryContact{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.temporaryContact__h1{
    color: hsl(350, 79%, 50%);
    font-weight: 400;
    font-size: 400%;
    padding: 1rem;
}

.temporaryContact__ul{
    list-style: none;
}

.temporaryContact__li{
    font-size: 300%;
    padding: 1rem;
}

.temporaryContact__li__a{
    text-decoration: none;
    color: #eee;
}