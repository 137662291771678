.reactProjectCard{
    padding: 14rem 10rem;
    height: 100vh;
    width: 100%;
    position: relative;
}

.reactProjectCard__img{
    position: absolute;
    top: 14rem;
    left: 10rem;
    width: 60%;
    height: 60rem;
    object-fit: cover;
}

.reactProjectCard__info{
    position: absolute;
    bottom: 10rem;
    right: 10rem;
    height: 60rem;
    width: 60%;
    background: #1a1a2e;
    box-shadow: 0 0 2rem black;
    padding: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.reactProjectCard__h1{
    font-size: 400%;
}

.reactProjectCard__text{
    font-size: 130%;
}

.reactProjectCard__methods{
   
    list-style: none;
}

.reactProjectCard__method{
    width: 100%;
    font-size: 150%;
}

.reactProjectCard__links{

}

.reactProjectCard__button{
    font-size: 150%;
    background: none;
    color: white;
    border: solid 2px white;
    padding: 0.3rem;
    border-radius: 0.5rem;
    cursor: pointer;
    text-decoration: none;
    margin: 1rem;
}

.reactProjectCard__githubLink{
    color: white;
    font-size: 200%;
}