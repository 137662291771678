*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html{
    font-size: 62.5%;
    width: 100%;
}

body{
    color: #eee;
    min-height: 100vh;
    font-family: 'Roboto Mono', monospace, sans-serif;
    background: #1A1A2E;
}