.font-sizing{
    font-size: 160%;
}

header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 15vh;
    position: fixed;
    z-index: 999;
    width: 100%;
}

.logo{
    width: 70px;
    margin: 30px;
}

.mobile-nav-toggle{
    display: none;
}

.primary-nav{
    height: 8rem;
    display: flex;
}

.primary-navigation{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 2rem;
    list-style: none;
    background: hsl(0 0% 30% / 0.75);
}

@supports (backdrop-filter: blur(1rem)) {
    .primary-navigation{
        background: hsl(0 0% 100% / 0.1);
        backdrop-filter: blur(1rem);
    }
}

.primary-navigation a{
    color: white;
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
}

.primary-navigation a > span{
    font-weight: 700;
    margin-inline-end: 0.5rem;
}

@media screen and (max-width: 40rem) {
    .primary-navigation{
        position: fixed;
        z-index: 1000;
        inset: 0 0 0 30%;

        flex-direction: column;
        padding: min(30vh, 10rem) 2rem;

        transform: translateX(100%);
        transition: transform 350ms ease-out;
    }

    .primary-navigation[data-visible="true"] {
        transform: translateX(0%);

    }

    .mobile-nav-toggle{
        display: block;
        position: absolute;
        z-index: 9999;
        background-color: transparent;
        background-image: url("https://upload.wikimedia.org/wikipedia/commons/5/59/Hamburger_icon_white.svg");
        background-repeat: no-repeat;
        width: 3rem;
        border: 0;
        aspect-ratio: 1;
        top: 2rem;
        right: 2rem;
        cursor: pointer;
    }

    .mobile-nav-toggle[aria-expanded="true"]{
        background-size: contain ;
        background-image: url("https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/Cross_icon_%28white%29.svg/1024px-Cross_icon_%28white%29.svg.png?20200211170236");
    }
}

@media (min-width: 35em) and (max-width: 55rem){
    .primary-navigation a > [aria-hidden]{
        display: none;    
    }
}

@media (min-width: 35rem){
    .primary-navigation{
        padding-block: 1.7rem;
        padding-inline: clamp(3rem, 5vw, 10rem);
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}